export default {
  /**
   * SETTINGS
   */
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  /**
   * AUTH
   */
  SET_AUTH(state, auth) {
    state.auth = auth;
  },

  /**
   * USER
   */
  SET_USER(state, user) {
    state.user = user;
  },

  /**
   * PIN
   */
  SET_PIN(state, pin) {
    state.pin = pin;
  },

  /**
   * BOX
   */
  SET_BOX(state, box) {
    state.box = box;
  },

  /**
   * CART
   */
  SET_CART(state, storeCart) {
    state.storeCart = storeCart;
  },

  ADD_ITEM(state, storeCart) {
    state.storeCart = storeCart;
  },

  UPDATE_ITEM(state, storeCart) {
    state.storeCart = storeCart;
  },

  REMOVE_ITEM(state, storeCart) {
    state.storeCart = storeCart;
  },

  CREATE_ORDER(state, orders) {
    state.orders = orders;
  },

  SET_ORDER(state, orders) {
    state.orders = orders;
  },

  CLEAR_CART(state) {
    state.storeCart.items.forEach((p) => {
      const product = p;
      product.quantity = 1;
    });
    state.storeCart = {};
  },

  /**
   * PRODUCTS
   */
  SET_PRODUCTS(state, products) {
    state.products = products;
  },

  /**
   * PAYMENT - CATEGORIES
   */
  SET_PAYMENT_CATEGORIES(state, paymentCategories) {
    state.paymentCategories = paymentCategories;
  },
  SET_PAYMENT(state, payment) {
    state.payment = payment;
  },
  SET_PAYMENT_RETRY(state, paymentRetry) {
    state.paymentRetry = paymentRetry;
  },

  /**
   * GLOBAL LOADING
   */
  SET_GLOBAL_LOADING(state, globalLoading) {
    state.globalLoading = globalLoading;
  },

  /**
  * TRANSLATIONS
  */
  SET_I18N(state, i18nData) {
    state.i18nData = i18nData;
  },

  /**
   * PAGE TITLE
   */
  ADD_PAGE_TITLE(state, title) {
    state.pageTitle = title;
  },

  REMOVE_PAGE_TITLE(state, title) {
    if (!title) state.pageTitle = null;
  },

  ADD_PAGE_BACK_URL(state, url) {
    state.pageBackUrl = url;
  },

  REMOVE_PAGE_BACK_URL(state, url) {
    if (!url) state.pageBackUrl = '';
  },

  /**
   * SET TEST
   */
  SET_TEST(state, test) {
    state.test = test;
  },

  /**
   * SET ERROR
   */
  SET_ERROR(state, error) {
    state.error = error;
  },
};
