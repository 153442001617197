export default {
  settings: (state) => state.settings,

  auth: (state) => state.auth,
  user: (state) => state.user,
  pin: (state) => state.pin,
  box: (state) => state.box,
  storeCart: (state) => state.storeCart,
  orders: (state) => state.orders,
  products: (state) => state.products,
  paymentCategories: (state) => state.paymentCategories,
  payment: (state) => state.payment,
  paymentRetry: (state) => state.paymentRetry,

  globalLoading: (state) => state.globalLoading,
  i18nData: (state) => state.i18nData,

  pageTitle: (state) => state.pageTitle,
  pageBackUrl: (state) => state.pageBackUrl,

  test: (state) => state.test,

  error: (state) => state.error,
};
