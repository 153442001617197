/**
 * Library for date and time manipulation
 * https://github.com/iamkun/dayjs#readme
 */

import Vue from 'vue';
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

// Add dayjs to Vue prototype to access dayjs in every component
Vue.prototype.$date = dayjs;
