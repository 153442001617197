<template>
  <div id="app">

    <!-- navigation START -->
    <app-navigation
      :class="{ 'nav-screensaver' : $route.name === 'screensaver' }"
    />
    <!-- navigation END -->

    <!-- view START -->
    <div
      :class="[
        'view',
        { 'blue' : $route.name === 'index' || $route.name === 'cart-payment-methods'
           || $route.name === 'cart-app-payment' },
        { 'screensaver-holder' : $route.name === 'screensaver' }
      ]"
    >
      <transition
        name="fade-slow"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
    <!-- view END -->

    <!-- footer START -->
    <app-footer v-if="false" />
    <!-- footer END -->

    <idle v-if="isIdle" />

  </div>
</template>

<script>
export default {
  name: 'MainApp',
  components: {
    AppNavigation: () => import('./components/shared/Navigation.vue'),
    AppFooter: () => import('./components/shared/Footer.vue'),
    Idle: () => import('./components/shared/dialog/Idle.vue'),
  },
  computed: {
    isIdle() {
      if (this.$route.name !== 'screensaver') {
        return this.$store.state.idleVue.isIdle;
      }
      return false;
    },
  },
};
</script>
